<template>
  <div class="table-responsive mb-4">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Product Name</th>
        <th>Batch</th>
        <th>Mnf. Date</th>
        <th>Exp. Date</th>
        <th>Sale Rate</th>
        <th>Rate</th>
        <th>Qty</th>
        <th v-if="isPartial">Prev Rtn Qty</th>
        <th v-if="isPartial">Return Qty</th>
        <th>Discount</th>
        <th>VAT</th>
        <th>Total</th>
        <th>Return Total</th>
      </tr>
      </thead>
      <tbody v-if="itemDetails">
      <tr v-for="(item, index) in itemDetails">
        <td>{{ item.product_name }}</td>
        <td>{{ item.batch_number }}</td>
        <td>{{ item.mfn_date }}</td>
        <td>{{ item.exp_date }}</td>
        <td>{{ item.selling_rate }}</td>
        <td>{{ item.rate }}</td>
        <td>{{ item.quantity }}</td>
        <td v-if="isPartial">{{ item.prev_return_qty }}</td>
        <td v-if="isPartial" style="min-width: 120px">
          <input
            id="discount-amount"
            v-model.trim="item.return_qty"
            type="number"
            class="form-control form-control-sm discount-adj ml-auto text-right"
            placeholder="amount"
            :min="0"
            :max="item.quantity"
            step="1"
            @keyup="(e) => validateReturnQty(e, index)"
          >
        </td>
        <td>{{ item.discount_percent ? `${item.discount_percent}%` : '' }}</td>
        <td>{{ item.vat_amount ? `${item.vat_amount}` : '' }}</td>
        <td>{{ item.g_total }}</td>
        <td>{{ item.return_qty ? item.rate * item.return_qty : 0 }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {inject} from 'vue'

const props = defineProps({
  itemDetails: {
    type: Array,
    default: []
  },
  formRef: {
    type: Object,
  },
  isPartial: {
    type: Boolean,
    default: false
  }
})

const showError = inject("showError");

const validateReturnQty = (e, index) => {
  const qty = e.target.value;
  const item = props.itemDetails[index];
  const remainingQty = item.quantity - item.prev_return_qty;
  item.return_qty = parseInt(qty);

  if (qty > remainingQty) {
    showError("Return quantity cannot be more than remaining quantity");
    item.return_qty = remainingQty;
  }
}


</script>